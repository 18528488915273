
import { defineComponent, PropType, toRefs, watch, ref } from 'vue';
import { RevenueDailyResponse } from '@/services/api';
import dayjs from 'dayjs';

const formatTime = (time: string) => {
  if (!time) return;

  const TIME_FORMAT = 'YYYY-MM-DD';
  return dayjs(time).format(TIME_FORMAT);
};

export default defineComponent({
  props: {
    revenueList: {
      type: Object as PropType<RevenueDailyResponse['data']['revenueList']>,
      default: () => ({})
    },
    dateRange: {
      type: Array as PropType<string[]>,
      default: () => []
    }
  },
  emits: ['pageChange'],
  setup(props, { emit }) {
    const { dateRange } = toRefs(props);

    const handlePageChange = (newPage: number) => {
      emit('pageChange', newPage);
    };

    const dateValues = ref(dateRange.value);
    watch(dateRange, (dateRange) => {
      (dateValues.value) = dateRange;
    });

    return {
      handlePageChange,
      formatTime
    };
  }
});
