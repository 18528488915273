import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f03ec42e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dashboard-editor-container" }
const _hoisted_2 = { class: "chart-wrapper" }
const _hoisted_3 = { class: "demo-date-picker" }
const _hoisted_4 = { class: "block" }
const _hoisted_5 = { class: "chart-wrapper" }
const _hoisted_6 = { class: "chart-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PanelGroup = _resolveComponent("PanelGroup")!
  const _component_DateRangePicker = _resolveComponent("DateRangePicker")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_RevenueList = _resolveComponent("RevenueList")!
  const _component_LineChartRevenue = _resolveComponent("LineChartRevenue")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PanelGroup, { "revenue-daily": $setup.revenueDaily }, null, 8, ["revenue-daily"]),
    _createVNode(_component_el_row, { gutter: 40 }, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, null, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_DateRangePicker, {
                    disabled: "",
                    limitDayCount: 7,
                    dateRange: $setup.dateRange,
                    onChange: $setup.setDateRange
                  }, null, 8, ["dateRange", "onChange"])
                ])
              ])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_row, { gutter: 40 }, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, null, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_RevenueList, {
                "revenue-list": $setup.revenueDaily?.revenueList,
                dateRange: $setup.dateRange,
                onPageChange: $setup.handlePageChange
              }, null, 8, ["revenue-list", "dateRange", "onPageChange"])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_row, { gutter: 40 }, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, null, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_LineChartRevenue, {
                "revenue-list": $setup.revenueDaily?.revenueList,
                dateRange: $setup.dateRange
              }, null, 8, ["revenue-list", "dateRange"])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}