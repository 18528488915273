
import { onMounted, ref } from 'vue';

import PanelGroup from './components/PanelGroup.vue';
import LineChartRevenue from './components/LineChartRevenue.vue';
import RevenueList from './components/RevenueList.vue';
import DateRangePicker from '@/components/date-range-picker/Index.vue';
import dayjs from 'dayjs';

import {
  getRevenueDaily,
  RevenueDailyResponse,
  AnalyticQuery
} from '@/services/api';

export default {
  components: {
    PanelGroup,
    LineChartRevenue,
    RevenueList,
    DateRangePicker
  },
  setup() {
    const revenueDaily = ref<RevenueDailyResponse['data']>({});

    const defaultStartTime = dayjs().subtract(6, 'day').format('YYYY-MM-DD');
    const defaultEndTime = dayjs().format('YYYY-MM-DD');
    const dateRange = ref([defaultStartTime, defaultEndTime]);

    const fetchRevenueDaily = async({ page = 1 }: AnalyticQuery = {}) => {
      const { data } = await getRevenueDaily({
        query: {
          // 2021-12-05 2021-12-31
          ...(dateRange.value && {
            date: `${dateRange.value[0]} ${dateRange.value[1]}`
          }),
          page
        }
      });

      revenueDaily.value = data;
    };

    const handlePageChange = (newPage: number) => {
      fetchRevenueDaily({ page: newPage });
    };

    const setDateRange = (_dateRange: string[]) => {
      dateRange.value = _dateRange;
      fetchRevenueDaily();
    };

    onMounted(async() => {
      fetchRevenueDaily();
    });

    return {
      dateRange,
      fetchRevenueDaily,
      handlePageChange,
      revenueDaily,
      setDateRange
    };
  }
};
